


























































import { Component, Prop } from 'vue-property-decorator'
import shave from 'shave'

// types
import {
  DashboardEducationExerciseBlockExerciseResource,
  DashboardEducationExerciseBlockResource,
  NameValueResource,
} from '@/store/types'
// components
import Tag from '@/components/_uikit/Tag.vue'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
// utils
import AuthModule from '@/store/modules/auth'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { declension, parseDateToMilliseconds } from '@/utils/functions'

@Component({
  components: {
    ButtonArrow,
    Tag,
  },
})
export default class DashboardExercises extends MasterGroupMixin {
  @Prop({ default: null })
  private exercisesData!: DashboardEducationExerciseBlockResource

  @Prop({ default: 0 })
  private availableExercises!: number

  private currentExerciseIndex = 0
  private amount = this.exercisesData.exercises.length
  private checkedAmount = this.exercisesData.completedCount
  private currentDeadlineTime: NameValueResource[] = []
  private days = 0
  private hours = 0
  private minutes = 0
  private seconds = 0

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get currentTask() {
    this.currentDeadlineTime = this.timeUntillDeadLine(this.exercisesData.exercises[this.currentExerciseIndex])
    return this.exercisesData.exercises[this.currentExerciseIndex]
  }

  private get isNextButtonShow() {
    if (this.currentExerciseIndex === this.amount - 1 || this.amount - 1 <= 0) {
      return false
    }
    return true
  }

  private get isPreviousButtonShow() {
    if (this.currentExerciseIndex === 0) {
      return false
    }
    return true
  }

  private get uncheckedAmount() {
    return this.educationStatsBlock ? this.educationStatsBlock.totalExercises - this.checkedAmount : 0
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    this.shave()
  }

  private timeUntillDeadLine(exercise: DashboardEducationExerciseBlockExerciseResource): NameValueResource[] {
    const deadline = parseDateToMilliseconds(exercise.deadlineAt)
    const secondsTill = (deadline - this.currentTime) / 1000
    let result: NameValueResource[] = []

    this.days = Math.floor((secondsTill / 86400))
    this.hours = Math.floor((secondsTill - this.days * 86400) / 3600)

    this.minutes = Math.floor((secondsTill - (this.hours * 3600 + this.days * 86400) ) / 60)
    this.seconds = Math.floor(secondsTill - (this.minutes * 60 + this.hours * 3600 + this.days * 86400))

    if (this.days < 0) {
      return result
    }

    if (this.days > 0) {
      result = [
        { name: declension(this.days, ['день', 'дня', 'дней']), value: this.days },
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
      ]
    }

    else if (this.hours > 0) {
      result = [
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
        { name: 'мин', value: this.minutes },
      ]
    }

    else if (this.minutes >= 0) {
      result = [
        { name: 'мин', value: this.minutes },
        { name: 'сек', value: this.seconds },
      ]
    }

    return result
  }

  private handlePrev() {
    if (this.exercisesData) {
      if (this.currentExerciseIndex !== 0) {
        this.currentExerciseIndex -= 1
        this.shave()
      }
    }
  }

  private handleNext() {
    if (this.exercisesData) {
      if (this.currentExerciseIndex !== this.amount - 1) {
        this.currentExerciseIndex += 1
        this.shave()
      }
    }
  }

  private shave() {
    this.$nextTick(() => {
      this.exercisesData.exercises.forEach(element => {
        shave(`#small-container-${element.id}`, 64, { spaces: false })
      })
    })
  }
}
