




































































import { Component, Prop } from 'vue-property-decorator'

// types
import {
  DashboardEducationLessonBlockLessonResource,
  DashboardEducationLessonBlockResource,
  NameValueResource,
} from '@/store/types'
// components
import Tag from '@/components/_uikit/Tag.vue'
import { declension, parseDateToMilliseconds } from '@/utils/functions'
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import DictionaryModule from '@/store/modules/dictionary'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonArrow,
    Tag,
  },
})
export default class DashboardLessons extends MasterGroupMixin {
  @Prop({ default: null })
  private lessonsData!: DashboardEducationLessonBlockResource

  private lessons: DashboardEducationLessonBlockLessonResource[] = []

  @Prop({ default: 0 })
  private availableLessons!: number

  private currentLessonIndex = 0

  private checkedAmount = this.lessonsData.checkedCount
  private currentDeadlineTime: NameValueResource[] = []
  private days = 0
  private hours = 0
  private minutes = 0
  private seconds = 0

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get amount() {
    return this.lessons.length
  }

  private get currentTask() {
    if (this.lessons.length > 0) {
      this.currentDeadlineTime = this.timeUntilDeadLine(this.lessons[this.currentLessonIndex])
      return this.lessons[this.currentLessonIndex]
    }
    return null
  }

  private get isNextButtonShow() {
    return !(this.currentLessonIndex === this.amount - 1 || this.amount - 1 <= 0)
  }

  private get isPreviousButtonShow() {
    return this.currentLessonIndex !== 0
  }

  private get uncheckedAmount() {
    return this.educationStatsBlock ? this.educationStatsBlock.totalLessons - this.checkedAmount : 0
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    // ES-1765
    let liveLessons: DashboardEducationLessonBlockLessonResource[] = []
    let passedLessons: DashboardEducationLessonBlockLessonResource[] = []
    let idleLessons: DashboardEducationLessonBlockLessonResource[] = []

    this.lessonsData.lessons.forEach(lesson => {
      if (lesson.isSpending && this.isCurrentlyLive(lesson)) {
        liveLessons.push(lesson)
      } else if (lesson.hasVideo || lesson.isDone) {
        passedLessons.push(lesson)
      } else {
        idleLessons.push(lesson)
      }
    })

    liveLessons = liveLessons.sort((a, b) => {
      return parseDateToMilliseconds(a.spendingAt) - parseDateToMilliseconds(b.spendingAt)
    }).reverse()

    passedLessons = passedLessons.sort((a, b) => {
      return parseDateToMilliseconds(a.spendingAt) - parseDateToMilliseconds(b.spendingAt)
    }).reverse()

    idleLessons = idleLessons.sort((a, b) => {
      return parseDateToMilliseconds(a.spendingAt) - parseDateToMilliseconds(b.spendingAt)
    }).reverse()

    this.lessons = this.lessons.concat(liveLessons)
    this.lessons = this.lessons.concat(passedLessons)
    this.lessons = this.lessons.concat(idleLessons)
    this.lessons = this.lessons.reverse()

    const currentIndex = this.lessons.findIndex(lesson => lesson.position === 0)

    this.currentLessonIndex = currentIndex !== -1 ? currentIndex : this.lessons.length ? this.lessons.length - 1 : 0
  }

  private messageState(currentLesson: DashboardEducationLessonBlockLessonResource) {
    if (currentLesson.isDone) {
      return 'завершен'
    }
    else if (currentLesson.isSpending && this.isCurrentlyLive(currentLesson)) {
      return 'идет'
    }
  }

  private isCurrentlyLive(currentLesson: DashboardEducationLessonBlockLessonResource) {
    return ((parseDateToMilliseconds(currentLesson.spendingAt) + (process.env.VUE_APP_LESSON_DURATION || 3) * 3600 * 1000) > this.currentTime)
  }

  private timeUntilDeadLine(exercise: DashboardEducationLessonBlockLessonResource): NameValueResource[] {
    const deadline = parseDateToMilliseconds(exercise.spendingAt)
    const secondsTill = (deadline - this.currentTime) / 1000
    let result: NameValueResource[] = []

    this.days = Math.floor((secondsTill / 86400))
    this.hours = Math.floor((secondsTill - this.days * 86400) / 3600)

    this.minutes = Math.floor((secondsTill - (this.hours * 3600 + this.days * 86400) ) / 60)
    this.seconds = Math.floor(secondsTill - (this.minutes * 60 + this.hours * 3600 + this.days * 86400))

    if (this.days < 0) {
      return result
    }

    if (this.days > 0) {
      result = [
        { name: declension(this.days, ['день', 'дня', 'дней']), value: this.days },
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
      ]
    }

    else if (this.hours > 0) {
      result = [
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
        { name: 'мин', value: this.minutes },
      ]
    }

    else if (this.minutes >= 0) {
      result = [
        { name: 'мин', value: this.minutes },
        { name: 'сек', value: this.seconds },
      ]
    }

    return result
  }

  private handlePrev() {
    if (this.lessonsData) {
      if (this.currentLessonIndex !== 0) {
        this.currentLessonIndex -= 1
      }
    }
  }

  private handleNext() {
    if (this.lessonsData) {
      if (this.currentLessonIndex !== this.amount - 1) {
        this.currentLessonIndex += 1
      }
    }
  }

  private followLink(link: string) {
    window.open(link, '_blank')
  }
}
