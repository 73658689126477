










import { Component, Prop } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
@Component({
  components: {
    ButtonArrow,
    Confirmation,
  },
})
export default class DashboardHelp extends NotifyMixin {
  @Prop({ required: true })
  private block!: any

  private get lessonImage() {
    return require(`@/assets/images/icons/subjects/${this.block.alias}.svg`)
  }

  private goToLink() {
    window.location.href = this.block.url
  }
}
